<template>
  <div id="course-list">
    <div class="courses">
      <div
        class="course"
        v-for="(course, index) in courseList"
        :key="index"
        @click="toastAlert"
      >
        <div class="combo">
          <div class="course">
            <img :src="course.cover" alt="" />
            <div class="courseName">
              <div>{{ course.name }}</div>
              <div
                v-if="course.superType && course.superType.value == '0'"
                class="l-course"
              >
                班课规划
              </div>
              <div v-else class="l-plan">课 程</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Pagination
      v-model="currentPage"
      :total-items="total"
      :items-per-page="pageSize"
      @change="getOrderList(currentPage)"
    ></Pagination>
  </div>
</template>
<script>
import { getCourseList } from "@/service/userCenter";
import { Pagination, Toast } from "vant";
export default {
  name: "OrderList",
  components: {
    Pagination,
  },
  data() {
    return {
      courseList: [],
      currentPage: 1,
      total: 0,
      orderType: "",
      pageSize: 10,
    };
  },
  mounted() {
    this.getCourseList();
  },
  methods: {
    getCourseList(page) {
      this.currentPage = page ? page : 1;
      getCourseList(this.currentPage, this.pageSize)
        .then((res) => {
          if (!res.result.code) {
            this.courseList = res.result.records;
            this.total = res.result.total;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    toastAlert() {
      Toast("请至PC端观看课程!");
    },
  },
};
</script>
<style lang="scss" scoped>
#course-list {
  margin-top: 54px;
}
.courses {
  padding: 10px 0;
  background: #f8f8f8;
  .course {
    background: #fff;
    margin-bottom: 10px;
    padding: 0 12px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.combo {
  .course {
    padding: 12px 8px;
    display: flex;
    flex-wrap: nowrap;
    img {
      width: 110px;
      height: 62px;
      border-radius: 2px;
      margin-right: 10px;
      vertical-align: top;
    }
    .courseName {
      width: calc(100% - 120px);
      font-size: 12px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.8);
    }
  }
}
.total {
  font-size: 10px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #979797;
  line-height: 42px;
  .price {
    font-size: 12px;
    color: #fd1d1d;
  }
  .operatebutton {
    float: right;
    display: flex;
    transform: translateY(6px);
    .cancel {
      font-size: 12px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #979797;
      background: #fff;
    }
    .pay {
      background: linear-gradient(
        135deg,
        #ff7809 0%,
        #ffcf00 100%,
        #ff9568 100%
      );
      color: #fff;
    }
  }
}
.l-course {
  background: #fa6400;
  width: 75px;
  letter-spacing: 1px;
}

.l-plan {
  background: #fcb045;
  width: 50px;
}
.l-course,
.l-plan {
  font-size: 12px;
  color: white;
  border-radius: 30px;
  text-align: center;
  height: 18px;
  line-height: 18px;
  margin-top: 8px;
}
</style>