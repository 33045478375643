import request from "@/utils/request";


export async function getOrderList(orderStatus, page, size) {
    try {
        return await request({
            url: `/order?orderStatus=${orderStatus}&current=${page}&size=${size}`,
            method: "get",
        });
    } catch (error) {
        return {};
    }
}
export async function getCourseList(page, size) {
    try {
        return await request({
            url: `/userCourse/pageUserUserCourse?current=${page}&size=${size}`,
            method: "get",
        });
    } catch (error) {
        return {};
    }
}